import styled from 'styled-components';

export const Body = styled.div`
  text-align: center;
`;
export const ImageStyle = styled.img`
  width: auto;
  height: 120px;
  border: 0;
  vertical-align: top;
  margin: 0 0 30px 0;
  @media (max-width: 991px) {
    height: 150px;
  }
`;

export const LinkStyle = styled.a`
  font-family: 'Adelle-Bold', sans-serif;
  font-size: 17px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
  color: #55aa4c;
  transition: 0.25s ease-out;
  code {
    display: inline-block;
    position: relative;
    padding: 16px 60px 14px;
    font-family: interstate, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    margin-right: 0px;
    color: rgb(255, 255, 255);
    background-color: rgb(31, 178, 90);
    border-bottom: 4px solid rgb(0, 135, 82);
    text-decoration: none;
    :hover {
      background-color: #55aa4c;
    }
  }
  :hover {
    color: #0397d6;
  }
`;
export const Header3 = styled.h3`
  margin: 0px 0px 40px;
  font-family: interstate, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 35px;
  line-height: 40px;
  color: rgb(0, 105, 170);
  text-align: left;
  @media (max-width: 767px) {
    margin: 0px 0px 30px;
    font-size: 20px;
    line-height: 22px;
  }
`;
export const SpanStyle = styled.div`
  position: absolute;
  top: -75px;
  @media (max-width: 1199px) {
    top: -80px;
  }
  @media (max-width: 767px) {
    top: -90px;
  }
`;
export const HeaderH4 = styled.h4`
  margin: 0px 0px 40px;
  padding: 0px 0px 15px;
  font-family: interstate, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 22px;
  line-height: 26px;
  text-align: left;
  text-transform: uppercase;
  color: rgb(3, 151, 214);
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
  overflow: hidden;
  @media (max-width: 767px) {
    margin: 0px 0px 30px;
    font-size: 18px;
    line-height: 20px;
  }
`;

export const ParaText = styled.p`
  margin: 0 0 40px 0;
  padding: 0;
  text-align: left;
  font-family: 'Gotham-Regular', arial, sans-serif !important;
  font-size: 17px !important;
  line-height: 32px !important;
  color: rgba(0, 0, 0, 0.8) !important;
  a > code {
    display: inline-block;
    position: relative;
    padding: 16px 60px 14px;
    font-family: interstate, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    margin-right: 0px;
    color: rgb(255, 255, 255);
    background-color: rgb(31, 178, 90);
    border-bottom: 4px solid rgb(0, 135, 82);
    text-decoration: none;
    @media (max-width: 767px) {
      font-size: 15px !important;
      line-height: 23px !important;
    }
    :hover {
      background-color: #0397d6;
    }
  }

  @media (max-width: 1399px) {
    margin: 0 0 30px 0;
  }
  @media (max-width: 767px) {
    font-size: 15px !important;
    line-height: 23px !important;
  }
`;
export const ListContainer = styled.ul`
  padding: 0;
  margin: 0 0 30px 0;
`;
export const ListText = styled.li`
  padding: 0 0 15px 30px;
  background-size: 10px auto;
  list-style: none;
  margin: 0;
  ${ParaText} {
    margin: 0;
  }
  @media (max-width: 1399px) {
    background-position: 0 9px;
  }
  @media (max-width: 767px) {
    background-position: 0 5px;
  }
`;
export const HeaderH2 = styled.h2`
  text-align: left;
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'Adelle-Bold', sans-serif;
  font-size: 45px;
  line-height: 50px;
  color: #0e4c80;
  @media (max-width: 1399px) {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;
export const ItalicParaText = styled(ParaText)`
  font-style: italic;
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56%;
  margin: 40px 0 40px 0;
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 3px #969696;
`;
export const Header = styled.h1`
  font-family: 'Adelle-Bold', sans-serif;
  font-weight: normal !important;
  color: #0e4c80;
  font-size: 45px;
  line-height: 50px;
  margin-bottom: 10px;
  @media (max-width: 1399px) {
    font-size: 40px;
    line-height: 45px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;
